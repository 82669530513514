/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */
import {RemixBrowser, useLocation, useMatches}  from "@remix-run/react";
import * as Sentry                              from "@sentry/remix";
import {startTransition, StrictMode, useEffect} from "react";
import {hydrateRoot}                            from "react-dom/client";

if (process.env.NODE_ENV === "production") {
    Sentry.init({
        dsn             : "https://39272ac1083e6bb95b23705b0fd51f52@o4505397567946752.ingest.us.sentry.io/4508148156530688",
        tracesSampleRate: 1,

        integrations: [Sentry.browserTracingIntegration({
            useEffect,
            useLocation,
            useMatches,
        }), Sentry.replayIntegration({
            maskAllText  : true,
            blockAllMedia: true,
        })],

        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1,
    });
}


startTransition(() => {
    hydrateRoot(
        document,
        <StrictMode>
            <RemixBrowser/>
        </StrictMode>,
    );
});
